import styled from 'styled-components'
import { keyframes } from 'styled-components'

//-------ANIMATION------------------
const hearthBeat = keyframes` 
    from {
        transform: scale(1);
        transform-origin: center center;
        animation-timing-function: ease-out;
      }
      10% {
        transform: scale(0.91);
        animation-timing-function: ease-in;
      }
      17% {
        transform: scale(0.98);
        animation-timing-function: ease-out;
      }
      33% {
        transform: scale(0.87);
        animation-timing-function: ease-in;
      }
      45% {
        transform: scale(1);
        animation-timing-function: ease-out;
      }

`
const colorChange = keyframes`
    from{
       filter:invert(50%);
    }

    25%{
        filter:invert(25%);
    }

    50%{
        filter:invert(0%);
    }

    75%{
        filter:invert(25%);
    }

    100%{
        filter:invert(50%);
    }
`


//----------------------------STYLES----------------------
export const IntroContainer= styled.div`
    //background: #0c0c0c;
    display:flex;
    justify-content:center;
    align-items:center;
    padding: 0 30px;
    height: 100vh;
    position: relative;
    z-index:1;

`

export const IntroBG = styled.div`
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    width:100%;
    height:100%;
    overflow:hidden;
`

export const VideoBG= styled.video`
    width:100%;
    height:100%;
    -o-object-fit:cover;
    object-fit:cover;
    margin-top:0px;
    z-index: 0;
`

export const IntroContent=styled.div`
    z-index: 3;
    max-width:1200px;
    //position:absolute;
    padding: 8px 24px;
    display:flex;
    flex-direction:column;
    align-items:center;
    overflow:auto;
`
export const IntroHeader = styled.h1`
   //@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Display&display=swap');
   color: transparent;
   font-size:10rem;
   text-align:center;
   font-family: Georgia, serif;
   letter-spacing: 1px;
   -webkit-text-stroke: 1.4px white;
   transform: scale(1.05, 1.1);
   

   @media screen and (max-width: 768px) {
       font-size:5rem;
   }
   
   @media screen and (max-width: 480px) {
       font-size:32px;
   }

`

export const ButtonWrapper=styled.div`
    margin-top:20px;
    display:flex;
    flex-direction:column;
    align-items: center;
`

export const ButtonMore=styled.a`
    @import url('https://fonts.googleapis.com/css2?family=Barlow:wght@300&display=swap');
    border-radius:50px;
    background: ${({primary}) => (primary ? '#01BF71' : '#010606' )};
    white-space:nowrap;
    padding: ${({big}) => (big ? '14px 48px' : '12px 30px')};
    color: ${({dark}) => (dark ? '#010606' : '#fff')};
    outline:none;
    border:none;
    cursor: pointer;
    display:flex;
    justify-content:center;
    align-items:center;
    transition: all 0.2s ease-in-out;
    text-decoration:none;
    font-family: 'Barlow', sans-serif;

    &:hover{
        transition: all 0.2s ease-in-out;
        background: #FF9300;
    }

`
export const ScrollSign = styled.img`
    margin-top:1.5rem;
    white-space:nowrap;
    width:50px;
    animation: ${hearthBeat} 3s ease-in-out 2s infinite both, ${colorChange} 10s ease-in-out 2s infinite;
    
`
