
import styled from 'styled-components'
//import { keyframes } from 'styled-components'
import {Parallax} from 'react-scroll-parallax'
import  { SocialIcon } from 'react-social-icons';

//-------ANIMATION------------------

export const Daddy = styled.div`
    display:flex;
    height: calc(100vh - 130px); //130 = 50 Footer + 80 Navbar
    width:100%;
    padding:0;
    justify-content:center;
    align-items:center;
    flex-direction:row;

    @media screen and (max-width: 768px) {
       flex-direction:column;
       min-height:calc(100vh - 130px);
   }

`
export const FirstHalf=styled.div`
    display:flex;
    height:inherit;
    width: 35%;
    align-items:center;
    justify-content:center;
    padding:0;

    @media screen and (max-width: 768px) {
       width: 100%;
       height:40%;
       background-color:black;
   }
`

export const SecondHalf = styled.div`
    display:flex;
    height:inherit;
    background-color: black;
    align-items:center;
    justify-content:center;
    right:0;
    left:0;
    width: 65%;
    align-items:flex-start;


    @media screen and (max-width: 768px) {
       width: 100%;
       height: 60%;
   }
`


export const InfoImage = styled.img`
    height:850px;
    z-index:2;
    position:absolute;
    align-self:flex-end;
    margin-left:12rem;
    @media screen and (max-width: 768px) {
        height:330px;
        margin-left:0rem;
        background-color:orange;
   }
`

export const InfoContainer = styled.div`
    padding-top:5rem;
    height:96%;
    display:flex;
    flex-direction:column;
    @media screen and (max-width: 768px) {
       align-items:center;
       justify-content:center;
       width:inherit;
       padding-top:0;
   }
`
export const InfoDescription = styled.p`
    @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200&display=swap');
    font-family: 'Raleway';
    font-size:1.6rem;
    font-smooth: auto;
    color:white;
    text-align:justify;
    width:inherit;
    line-height:1.5;
    margin-left:50px;
    @media screen and (max-width: 1200px) {
        font-size:1rem;
        line-height:1.2;
        margin-left:0;
        width:95%;
      
   }
`
export const QuoteContainer= styled.div`
    margin-top:3rem;
    width:inherit;
    margin-left:30rem;
    @media screen and (max-width: 1200px) {
        margin-left:0rem;
        width:100%;
        margin-top:2rem;
   }
`

export const Quote=styled(Parallax)`
    @import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Display:ital,wght@1,300&display=swap');
    color:white;
    font-family: 'Noto Serif Display', serif;
    font-size:2rem;
    align-self:center;
    text-align:center;
    font-style:italic;

    @media screen and (max-width: 1200px) {
        font-size:1.2rem;
        width:100%;
        margin-left: 22rem;
   }
`

export const Author = styled(Parallax)`
    color: #FF9300;
    align-self:center;
    font-size:1.2rem;
    margin-left:17rem;
    margin-top:2rem;
    
    @media screen and (max-width: 1200px) {
        font-size:1rem;
        margin-top:1rem;
   }
    
`

export const SocialsContainer = styled(Parallax)`
    align-self:flex-end;
    display:flex;
   justify-content:space-between;
   width:15rem;
   margin-top:-20px;
   @media screen and (max-width: 1200px) {
        width:70%;
        margin-top:-3rem;
        align-self:center;
   }
`
export const SocialsButton = styled(SocialIcon)`
    border-bottom: 0px solid;
    transition: all 0.2s ease-in-out;
    &:hover{
        border-bottom: 3px solid ${props=>props.HoverColor};
    }
    
`

export const InfoVidBG = styled.video`
    top:0;
    right:0;
    bottom:0;
    z-index:-100;
    height:inherit;
    box-sizing: border-box;
    
    @media screen and (max-width: 800px) {
        //overflow-y:hidden;
        //object-fit: fill;
        display:none;
   }


`

export const Follow = styled.h2`
    @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100&display=swap');
    color:white;
    padding-top:5rem;
    font-family: 'Raleway', sans-serif;
    padding-left:3rem;
    align-self:flex-end;
    @media screen and (max-width: 1200px) {
        font-size:1rem;
        padding-top:20px;
        padding-left:0rem;
        text-align:center;
        align-self:center;
   }

`