import React from 'react'
import IntroSection from '../components/IntroSection'
import Music from '../components/MusicSection';
import Store from '../components/StoreSection';
import Info from '../components/InfoSection'
import Footer from '../components/FooterSection';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import {useState} from 'react'
import { ParallaxProvider } from 'react-scroll-parallax';

const Home  = () => {

  const [isOpen, setIsOpen] =useState(false);
  const toggle = () => {
    setIsOpen(!isOpen) 
  }

  return (
      <>
      
      <Navbar toggle={toggle} />
      <Sidebar isOpen={isOpen} toggle={toggle}/>

      <ParallaxProvider>
      <section  id="home">
      <IntroSection />
      </section>
      </ParallaxProvider>

      <section  id="music">
      <Music />
      </section>
      
      <section  id="shop">
      <Store />
      </section>

      <ParallaxProvider>
      <section id="about">
      <Info />
      </section>
      </ParallaxProvider>

      <Footer />
      </>
    
  )
}

export default Home 