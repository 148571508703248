import React, {useState} from 'react'

import {Follow, Quote, QuoteContainer, Author, InfoVidBG, FirstHalf, SecondHalf, InfoContainer, InfoDescription, SocialsContainer, SocialsButton, Daddy ,InfoImage} from './InfoElements'
import TestProfile from '../../images/only_me_cut.png'
import Video from '../../video/About_Background.mp4'

const Info = () => {

  const [ParallaxSwitch,setParSwitch] =useState(false);

  const ScreenSize =() =>{
    if(window.innerWidth <= 1200){
      setParSwitch(true);
    }
    else{
      setParSwitch(false);}
  };

  window.addEventListener('resize', ScreenSize);

  return (
    <Daddy>
      <FirstHalf>
      
        <InfoImage as="img" src={TestProfile}  alt="eduxus"/>
     

        <InfoVidBG as='video' muted autoPlay loop src={Video} type='video/mp4' alt='backgroundVideo'/>
      </FirstHalf>

      <SecondHalf>
        <InfoContainer>
            <InfoDescription>
            From an early stage in his career, Eduxus' sound became characteristic<br/> 
            across various genres of electronic music. His exponential growth in popularity<br/> 
            has been attributed to his energy packed productions and the unique sounds<br/>
            used in each song. Supported by various famous DJs accross the globe, <br/> 
            Eduxus' music turns every major festival and club into the ultimate party.
            </InfoDescription>
          
            <QuoteContainer>

            
              <Quote translateX={['-80', '0']} easing="easeInQuad" disabled={ParallaxSwitch}>
              "Each song is a unique journey, a unique story,<br /> you want the listener to feel the song was made <br />
                for them, a reflection of their own journey"
              </Quote>

              
              <Author  translateX={['-30', '0']} easing="easeInQuad" disabled={ParallaxSwitch}>
                  - EDUXUS
              </Author>
        

            </QuoteContainer>
         
            <Follow>Follow my Journey :</Follow>

            <SocialsContainer speed={10}>
                <SocialsButton title='Facebook' HoverColor='#3b5998' url='https://www.facebook.com/Eduxus.Official'  ></SocialsButton>
                <SocialsButton title='Instagram' HoverColor='#e95950' url='https://www.instagram.com/eduxus_official' ></SocialsButton>
                <SocialsButton title='YouTube' HoverColor='#ff0000' url='https://www.youtube.com/channel/UCJoV2SLxUwJt8BSyhPHQ3CQ' ></SocialsButton>
                <SocialsButton title='SoundCloud' HoverColor='orange' url='https://soundcloud.com/eduxus-official' ></SocialsButton>
            </SocialsContainer>
        </InfoContainer>
      </SecondHalf>
    </Daddy>
  )
}

export default Info