import React from 'react';
import { SidebarContainer,Icon, CloseIcon,SidebarWrapper,SidebarMenu,SidebarLink } from './SidebarElements';

const Sidebar = ({isOpen,toggle}) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
            <CloseIcon />   
        </Icon>
        <SidebarWrapper>
            <SidebarMenu>

                <SidebarLink to="music" spy={true} smooth={true} offset={-80} duration={600} onClick={toggle}>
                    MUSIC
                </SidebarLink>

                <SidebarLink to="shop" spy={true} smooth={true} offset={-80} duration={600} onClick={toggle}>
                    SHOP
                </SidebarLink>

                <SidebarLink to="about" spy={true} smooth={true} offset={-80} duration={600} onClick={toggle}>
                    ABOUT
                </SidebarLink>


            </SidebarMenu>
        </SidebarWrapper>
    </SidebarContainer>

  )
}

export default Sidebar;