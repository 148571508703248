import styled from 'styled-components'
import { keyframes } from 'styled-components'

//--------------------animations-----------

const buttonAttention = keyframes`
    0% {
      transform: translateZ(0);
    }
    100% {
      transform: translateZ(160px);
    }
  `
//-------------------styles---------------------------
export const MainContainer = styled.div`
    display:flex;
    flex-direction:column;
    justify-content:flex-start;
    min-height:100vh;
    width:calc(100% -2rem);
    background-color:#dddddd;
    padding-left:2rem;

    @media screen and (max-width: 720px) {
        padding-left:0rem;
    }

`
export const MusicTitle = styled.h1`
    align-self: center;
    font-family: 'Barlow', sans-serif;
    font-size:3rem;
    padding-bottom:2rem;

`

export const MusicContainer = styled.div`
    display:flex;

    @media screen and (max-width: 720px) {
       flex-direction:column;
       align-items: center;
   }
    
`

export const MusicEach= styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    padding:1.2rem;

    @media screen and (max-width: 720px) {
       
   }

`

export const SpotifyPreview = styled.iframe`
    margin-bottom:1rem;
    transition: all 0.5s ease;
    &:hover{
        box-shadow:10px 5px 5px red;
    }

`
export const MorePlatformsButton = styled.button`
    width:10rem;
    transition: all 0.5s ease;
    height:2.5rem;
    border: 2px solid black;
    cursor: pointer;
    border-radius:16px;
    text-decoration: none;
    background: linear-gradient(to right, #000 50%, #fff 50%);
    background-size: 200% 100%;
    background-position: bottom right;
    color: #000;
    text-align: center;
    display: inline-block;
    display: block;
    line-height: 35px;
    font-family: 'Barlow', sans-serif;

    &:hover{
        border: 2px solid limegreen;
        background-position: bottom left;
        color: #fff;
    }

`

export const SoundcloudButton = styled.a`
    width:13rem;
    height:3rem;
    margin-top:2rem;
    transition: all 0.5s ease;
    cursor: pointer;
    text-decoration: none;
    padding: 7px 10px;
    background-color: #122;
    border-radius: 3px;
    color: #FFF;
    left: 15px;
    bottom: 15px;
    font-family: 'Barlow', sans-serif;
    margin-left:1.2rem;
    display: inline-block;
    display: block;
    line-height: 45px;
    font-size: 0.95rem;
    &:hover{
        filter: invert(100%);
        animation: ${buttonAttention} 0.70s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    @media screen and (max-width: 720px) {
       align-self:center;
       margin-bottom:1rem;
       margin-left:-3.8px
    }
`