import React from 'react'
import {StoreContainer, Info, Merch, CS, btnContainer, StoreBtn} from './StoreElements'

const Store = () => {
  return (
    <StoreContainer>

        <Info>
          <Merch>MERCHANDISE</Merch>
          <CS>COMING SOON</CS>
        </Info>
        <btnContainer>
            <StoreBtn title="RedBubble" href="https://www.redbubble.com/people/Eduxus/explore" target="_new" onclick="window.open(this.href); return false;"> Redbubble </StoreBtn>
            <StoreBtn title="TeeSpring" href="https://backstage-by-eduxus.creator-spring.com/" target="_new" onclick="window.open(this.href); return false;"> Tee Store</StoreBtn>
        </btnContainer>


    </StoreContainer>
  )
}

export default Store