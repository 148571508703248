import  React, {useState} from 'react';
import {Nav,NavLink,NavMenu,Bars,Title} from './NavbarElements';


const Navbar = ({toggle}) => {

    const [navbar,setNavbar] =useState(false);

    const changeBackground =() =>{
        if(window.scrollY >= 100){
            setNavbar(true);
        } else{setNavbar(false);}
    }

    window.addEventListener('scroll', changeBackground);

    return (
        <>
            <Nav className={navbar ? "active" : null }>
               <Title to="home" spy={true} smooth={true} offset={-80} duration={750}>EDUXUS</Title>
               <Bars onClick={toggle} className={toggle ? "active" : null }/>
                    <NavMenu>
                        <NavLink to="music" spy={true} smooth={true} offset={-80} duration={900}>MUSIC</NavLink>
                        <NavLink to="shop" spy={true} smooth={true} offset={-80} duration={900}>SHOP</NavLink>
                        <NavLink to="about" spy={true} smooth={true} offset={-80} duration={900}>ABOUT</NavLink>
                    </NavMenu>
            </Nav>
        </>
    );
};

export default Navbar;