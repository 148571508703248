import React from 'react'
import {MainContainer, MusicTitle, MusicContainer, SpotifyPreview, MorePlatformsButton, MusicEach, SoundcloudButton} from "./MusicElements.js"
function Music() {
  return (
    <MainContainer>
    
    <MusicTitle> RELEASES</MusicTitle>

    <MusicContainer>

      <MusicEach>
          <SpotifyPreview title='You'  style={{'border-radius':'12px'}} src="https://open.spotify.com/embed/track/7F2fdtbk2H1Uddy6VRp4Lf?utm_source=generator" width="100%" height="380" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"/>

          <MorePlatformsButton title='You' as="a" href="https://distrokid.com/hyperfollow/eduxus/you" target="_new" onclick="window.open(this.href); return false;">
          + PLATFORMS
          </MorePlatformsButton>
      </MusicEach>

      <MusicEach>
          <SpotifyPreview  title='Space Age' style={{'border-radius':'12px'}} src="https://open.spotify.com/embed/track/7BcbejDfCmmYdZrSGp1iNF?utm_source=generator" width="100%" height="380" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"/>

          <MorePlatformsButton title='Space Age' as="a" href="https://distrokid.com/hyperfollow/eduxus/space-age" target="_new" onclick="window.open(this.href); return false;">
          + PLATFORMS
          </MorePlatformsButton>
        </MusicEach>
      
    </MusicContainer>

      <SoundcloudButton title='To my SoundCloud' href="https://soundcloud.com/eduxus-official" target="_new" onclick="window.open(this.href); return false;"> MORE AT SOUNDCLOUD.COM  ➜  </SoundcloudButton>
      
    </MainContainer>
  )
}

export default Music