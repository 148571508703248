
import './App.css';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';

import Home from './pages/index'

function App() {
  return (
    <Router >
       <Switch>
       <Route path="/" exact component={Home}/>
       </Switch>
       
    </Router>
  );
}

export default App;
