
import styled from 'styled-components'

export const FooterContainer = styled.div`
    height:50px;
    width:100%;
    display:flex;
    background-color: black;
    justify-content:center;
    align-items:center;

`

export const FooterContent = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    
`

export const CopRight =  styled.h1`
    @import url('https://fonts.googleapis.com/css2?family=Barlow:wght@300&display=swap');
    font-family: 'Barlow', sans-serif;
    color:white;
    font-size:1rem;
    margin-right:10px;
    margin-left:10px;


`
export const Creator = styled.a`
    @import url('https://fonts.googleapis.com/css2?family=Barlow:wght@300&display=swap');
    font-family: 'Barlow', sans-serif;
    font-size:1rem;
    text-decoration:none;
    color: orange;
    margin-left:-3px;
    transition: all 0.5s ease;
    &:hover{
        background-color:orange;
        color:black;    
    }
    
`