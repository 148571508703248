import React from 'react'
import { FooterContainer,FooterContent,CopRight,Creator} from './FooterComponentes'

const Footer = () => {
  return (
    <FooterContainer>

        <FooterContent>
            <CopRight>COPYRIGHT ©2022 JSNZ</CopRight>
            <h2 style={{'color':'white'}}> | </h2>
            <CopRight>Made by:  </CopRight>

            <Creator title= "pss it's me" href="https://eduxus-jsnz.github.io/" target="_new" onclick="window.open(this.href); return false;"> JSNZ</Creator>
        </FooterContent>

    </FooterContainer>
  )
}

export default Footer