
import styled from 'styled-components'
import { keyframes } from 'styled-components'


//-----------------------------ANIMATION-----------------------
const GradientAnimation = keyframes` 
  0% {
	    	background-position: 0% 50%;
	    }
	    50% {
	    	background-position: 100% 50%;
	    }
	    100% {
	    	background-position: 0% 50%;
	    }
`



export const StoreContainer = styled.div`
    height: 100vh;
    backdrop-filter: blur(6px);
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction:column;

    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 300% 300%;
	animation: ${GradientAnimation} 15s ease infinite;
`

export const btnContainer =styled.div`
    display:flex;
    flex-direction:row;
    width:100%;
    align-items:center;
    justify-content:center;
`

export const Info =styled.div`
`

export const CS = styled.h1`
    @import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@600&display=swap');
    font-family: 'Playfair Display';
    font-size:5rem;
    @media screen and (max-width: 768px) {
       font-size:4rem;
   }
`

export const Merch =styled.h2`
    @import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
    font-family: 'Playfair Display';
`

export const StoreBtn =styled.a`
    font-size:1.5rem;
    margin-left:2rem;
    text-decoration:none;
    text-align:center;
    animation: all 0.5 ease-in-out;
    &:hover{
        color:white;
        -webkit-text-stroke-width: 0.5px;
        -webkit-text-stroke-color: black;
    }
`