import styled from 'styled-components'
//import {NavLink as Link} from  'react-router-dom'
import {Link} from  'react-scroll'
import {FaBars} from 'react-icons/fa'
import { keyframes } from 'styled-components'


//----------------------ANIMATIONS-------------------
const hovMenOpt = keyframes`        
    0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

//---------------------STYLES--------------------------
//Master
export const Nav = styled.nav`
background-color: transparent;//#222224; //4E4E50
height: 88px;
width:100%;
display: flex;
justify-content: space-between;
position: fixed; /* Set the navbar to fixed position */
padding: 0.5 rem;
//padding: 0.5 rem calc((100vw -1000px)/2);
z-index:10000;
transition:0.2s all ease; 

@media screen and (max-width:960px) {
 transition:0.8s all ease; 
}

  &.active{
    background-color:#0d0d0d;
  }
`;

//title
export const Title = styled(Link)`
  @import url("https://fonts.googleapis.com/css2?family=Barlow:wght@300&display=swap");
    color: white;
    height: 100%;
    display:flex;
    align-items:center;
    padding: 0 1rem;
    text-decoration: none;
    font-family: 'Barlow', sans-serif;
    font-size:2.5rem;
    margin-left:60px;
    cursor: pointer;

    &:hover{
        //animation: ${hovMenOpt}  1s ease-in-out infinite both;
        opacity:0.8;
        animation: 1s ease-in-out;
    }
    @media screen and (max-width: 1000px) {
        font-size:1.7rem;
        margin-left:10px; 
   }
`;
//Every menu option
export const NavLink = styled(Link)`
@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@100&display=swap");
color:#ffff;
display:inline-block;
align-items: center;
position:relative;
padding: 0 1rem;
height: 25%;
cursor: pointer;
font-family: 'Barlow', sans-serif;
font-size:1.3rem;
text-decoration:none;

&.active{
    color: #FF9300;
}

&:before{
  content: '';
	position: absolute;
  width: 80%;
  transform:scaleX(0);
	bottom: 0;
	left: 0.2rem;
	height: 0.25rem;
	background-color: white;
	transform-origin: bottom right;
	transition: transform 300ms ease-out;
} 

&:hover::before{
        //animation: ${hovMenOpt}  1s ease-in-out infinite both;
        transform: scaleX(1);
	      transform-origin: bottom left;
    }
`;

//Icon
export const Bars=styled(FaBars)`
display:none;
color: #fff;
margin-right:10px;
margin-top:4px;
&:active{
  color:red;
}


@media screen and (max-width: 768px){
    display:block;
    position:absolute;
    top:5px;
    right:0;
    transform: translate(-100%, 75%);
    font-size:1.8rem;

    @keyframes scale-in-center {
        0% {
          transform: scale(0);
          opacity: 1;
        }
        100% {
          transform: scale(1);
          opacity: 1;
        }
    }

    cursor:pointer;
    &:active{
      opacity:0%;
    }
}
`;

//all Options
export const NavMenu = styled.div`
display:flex;
align-items:center;
margin-right: 60px;

@media screen and (max-width: 768px){
    display:none;
}
`;
