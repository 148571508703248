import Video from '../../video/You_Preview.mp4'
import { ButtonMore,IntroContainer,IntroBG,VideoBG, IntroContent, IntroHeader, ButtonWrapper,ScrollSign } from './IntroElements'
import ArrowDown from '../../images/ArrowDown.png'
import {Parallax} from 'react-scroll-parallax'

const IntroSection = () => {

  return (
    <IntroContainer>
      
        <IntroBG>
            <VideoBG muted autoPlay loop src={Video} type='video/mp4' alt='backgroundVideo'/>
        </IntroBG>
       <Parallax speed={30} >
          <IntroContent> 

              <IntroHeader>YOU</IntroHeader>

              <ButtonWrapper>
                  <ButtonMore title='Listen "You" now' href="https://distrokid.com/hyperfollow/eduxus/you" target="_new" onclick="window.open(this.href); return false;"> LISTEN NOW</ButtonMore>
              </ButtonWrapper>

              <ScrollSign as="img" src={ArrowDown}  alt="downArrow"/>

          </IntroContent>
        </Parallax>
    </IntroContainer>
  )
}

export default IntroSection